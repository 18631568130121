<template>
  <div>
    <div class="loading">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import { Loading } from 'vant'

Vue.use(Loading)
export default {
  data() {
    return {}
  },
  created() {},
  computed: {},
  methods: {},
}
</script>
<style lang="less" scoped>
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.7);
  padding: 0.4rem;
  /deep/ .van-loading,
  /deep/ .van-loading__text {
    color: #fff;
  }
}
</style>
