<template>
  <!-- 添加企业信息 -->
  <div class="main-container">
    <span class="rem36 c-303133 f500">营业执照</span>
    <div class="uploadArea mt20">
      <!-- <div>
        <img :src="cameraImg" alt="" class="cardImg" />
        <input
          type="file"
          @change="uploadImg"
          name="imageIdCardHeads"
          id="imageIdCardHeads"
          accept="image/*"
          class="inputFile"
        />
      </div> -->
      <!-- <img v-show="isUpload" :src="uploadImgUrl" alt="" class="uploadImgUrl" /> -->
      <div :class="!uploadFront ? 'uploadFront' : ''">
        <van-uploader :after-read="compressFile" class="upload1s" v-if="!uploadFront">
          <img :src="cameraImg" alt="" class="cardImg" />
        </van-uploader>
        <van-uploader v-else :multiple="false" :after-read="compressFile">
          <img :src="frontFile.content" alt="" class="idCardImg" />
        </van-uploader>
      </div>
    </div>
    <p class="c-8E929B rem30 mt12 text-center">拍摄营业执照信息</p>
    <div class="mt24 confirm-info">
      <p class="c-8E929B">公司名称</p>
      <van-field label-width="0" v-model="companyInfo.name" placeholder="请输入公司名称" />
      <p class="mt20 c-8E929B">社会信用代码</p>
      <van-field label-width="0" v-model="companyInfo.certNo" placeholder="请输入社会信用代码" />
    </div>
    <div class="mt40">
      <van-button
        round
        block
        type="info"
        native-type="submit"
        :disabled="!companyInfo.name || !companyInfo.certNo"
        @click="addCompany"
        >保存</van-button
      >
    </div>
    <loading v-if="uploading"></loading>
  </div>
</template>
<script>
/* eslint-disable*/
import Vue from 'vue'
import { addEnterpriseInfoApi } from '@/apis/credit/index'
import { parseLicenseInfoApi, uploadFileNewApi } from '@/apis/userCenter'
import { Field, Cell, Button, Uploader } from 'vant'
import loading from '@/components/loading.vue'

import loadingIndex from '@/utils/overlay'
Vue.use(loadingIndex)

Vue.use(Field)
Vue.use(Cell)
Vue.use(Button)
Vue.use(Uploader)

export default {
  components: {
    loading,
  },
  data() {
    return {
      cameraImg: require('@/assets/images/credit/camera.png'),
      companyInfo: {
        name: '',
        certNo: '',
        legalPersonName: '',
        idNo: '',
      },
      uploadImgUrl: '',
      isUpload: false,
      uploading: false,
      fileJson: {},
      uploadFront: false,
      frontFile: {},
    }
  },
  created() {
    this.isUpload = false
  },
  computed: {},
  methods: {
    photoCompress(file, w, objDiv) {
      let that = this
      var ready = new FileReader()
      /*开始读取指定的Blob对象或File对象中的内容. 当读取操作完成时,readyState属性的值会成为DONE,如果设置了onloadend事件处理程序,则调用之.同时,result属性中将包含一个data: URL格式的字符串以表示所读取文件的内容.*/
      ready.readAsDataURL(file.file)
      ready.onload = function () {
        var re = that.result
        that.canvasDataURL(file.content, w, objDiv)
      }
    },
    convertBase64UrlToBlob(urlData) {
      var arr = urlData.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new Blob([u8arr], { type: mime })
    },
    canvasDataURL(path, obj, callback) {
      var img = new Image()
      img.src = path
      img.onload = function () {
        var that = this
        // 默认按比例压缩
        var w = that.width,
          h = that.height,
          scale = w / h
        w = obj.width || w
        h = obj.height || w / scale
        var quality = 0.4 // 默认图片质量为0.7
        //生成canvas
        var canvas = document.createElement('canvas')
        var ctx = canvas.getContext('2d')
        // 创建属性节点
        var anw = document.createAttribute('width')
        anw.nodeValue = w
        var anh = document.createAttribute('height')
        anh.nodeValue = h
        canvas.setAttributeNode(anw)
        canvas.setAttributeNode(anh)
        ctx.drawImage(that, 0, 0, w, h)
        // 图像质量
        if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
          quality = obj.quality
        }
        // quality值越小，所绘制出的图像越模糊
        var base64 = canvas.toDataURL('image/jpeg', quality)
        // 回调函数返回base64的值
        callback(base64)
      }
    },
    // 上传营业执照
    compressFile(file) {
      let that = this
      that.companyInfo = {
        name: '',
        certNo: '',
        legalPersonName: '',
        idNo: '',
      }
      var fileSize = parseFloat(parseInt(file.file.size) / 1024 / 1024).toFixed(2)

      if (Number(fileSize) > 10) {
        that.uploadFront = false
        this.$common.toast('上传图片太大，请重新拍照上传。')
        return
      }
      that.uploading = true
      that.frontFile = file
      that.uploadFront = true

      if (Number(fileSize) > 2 && file.file.type.indexOf('image') != '-1') {
        that.photoCompress(
          file,
          {
            quality: 0.2,
          },
          function (base64Codes) {
            //console.log("压缩后：" + base.length / 1024 + " " + base);
            var bl = that.convertBase64UrlToBlob(base64Codes)
            var newFile = new File([bl], file.file.name, { type: file.file.type.toLowerCase() })
            const formdata = new FormData()
            that.frontFile.file = newFile

            formdata.append('file', newFile)
            const query = {
              fileSeqNo: '3',
              fileTypeCode: '2', // 1 是身份证正面 传2 是营业执照 传3 是银行卡 传4 是身份证背面
            }
            uploadFileNewApi(query, formdata)
              .then((res) => {
                if (res.code === 200) {
                  that.fileId = res.data
                  parseLicenseInfoApi({
                    fileId: res.data,
                    seqNo: 3,
                  })
                    .then((result) => {
                      that.companyInfo = result.data
                      that.uploading = false
                    })
                    .catch(() => {
                      that.uploading = false
                    })
                }
              })
              .catch(() => {
                that.uploading = false
              })
          }
        )
      } else {
        const formdata = new FormData()
        formdata.append('file', this.frontFile.file)
        const query = {
          fileSeqNo: '3',
          fileTypeCode: '2', // 1 是身份证正面 传2 是营业执照 传3 是银行卡 传4 是身份证背面
        }
        uploadFileNewApi(query, formdata)
          .then((res) => {
            if (res.code === 200) {
              that.fileId = res.data
              parseLicenseInfoApi({
                fileId: res.data,
                seqNo: 3,
              })
                .then((result) => {
                  that.companyInfo = result.data
                  that.uploading = false
                })
                .catch(() => {
                  that.uploading = false
                })
            }
          })
          .catch(() => {
            that.uploading = false
          })
      }
    },
    showloading() {
      var title = '加载中···'
      this.$showLoading({
        title: title,
      })
    },

    hideloading() {
      this.$cancelLoading()
    },
    // 添加企业信息
    addCompany() {
      this.showloading()
      let params = {
        enterpriseName: this.companyInfo.name, // 企业名称
        enterpriseCertNo: this.companyInfo.certNo, // 企业注册号
        enterpriseKind: '', // 企业类型
        enterpriseAddress: this.companyInfo.address, // 企业地址
        legalPersonName: this.companyInfo.legalRepName, // 法定代表人
        registCapital: '', // 注册资本
        startDate: '', // 成立日期
        termStart: '', // 开始日期
        termEnd: '', // 结束日期
        enterpriseScope: '', // 经营范围
        licenseImgid: this.companyInfo.imgId,
      }

      addEnterpriseInfoApi(params)
        .then((res) => {
          if (res.code === 200) {
            this.hideloading()
            this.$router.push({
              path: 'company-info',
            })
          } else {
            this.hideloading()
          }
        })
        .catch(() => {
          this.hideloading()
        })
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
  padding: 0.16rem 0 0.12rem;
  border-bottom: 1px solid #e7e7e7;
}
.uploadArea {
  width: 6.7rem;
  height: 3.4rem;
  background: #f2f4f7;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  .cardImg {
    width: 0.86rem;
    height: 0.88rem;
  }
}
.idCardImg {
  width: 6.7rem !important;
  height: 3.4rem !important;
}
.inputFile {
  position: absolute;
  left: 0.4rem;
  top: 1.24rem;
  opacity: 0;
  height: 3.4rem;
  width: 6.7rem;
  z-index: 11;
}
.uploadImgUrl {
  position: absolute;
  height: 3.4rem;
  width: 6.7rem;
}
.upload1s {
  /deep/ .van-uploader__input {
    display: block;
    height: 3.4rem;
    overflow: hidden;
    cursor: pointer;
    opacity: 0;
    width: 6.7rem;
    left: -3rem;
    top: -1.2rem;
  }
}
</style>
